import React, { Fragment } from "react";


const Footer=()=>{

    return(
        <Fragment>
             <footer className="footer-area">
    <div className="footer-widget section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="single-widget single-widget1">
              <a href="index.html">
                <img
                  src="images/logo/spark.png"
                  style={{ width: 147, height: 119 }}
                  alt=""
                />
              </a>
              <p className="mt-3" style={{ color: "#fff" }}>
                Now you can order your favourite flavours of Spark with just a
                few clicks. But this legendary drink has humbler roots. Read on
                to know how Goli Soda came into the picture, briefly took a
                break and is now
              </p>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="single-widget single-widget2 my-5 my-md-0">
              <h5 className="mb-4">Quick links</h5>
              <div className="d-flex">
                <div className="into-icon">
                  {/* <i class="fa fa-map-marker"></i> */}
                </div>
                <div className="info-text">
                  <p style={{ color: "#fff" }}>Home</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="into-icon">
                  {/* <i class="fa fa-phone"></i> */}
                </div>
                <div className="info-text">
                  <p style={{ color: "#fff" }}>About US</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="into-icon" />
                <div className="info-text">
                  <p style={{ color: "#fff" }}>Blog</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="single-widget single-widget2 my-5 my-md-0">
              <h5 className="mb-4">Our Address</h5>
              <div className="d-flex">
                <div className="into-icon">
                  <i style={{color: "#fff"}} className="fa fa-map-marker" />
                </div>
                <div className="info-text">
                  <p style={{ color: "#fff" }}>
                    1234 Some St San Francisco, CA 94102, US 1.800.123.4567
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="into-icon">
                  <i style={{color: "#fff"}} className="fa fa-phone" />
                </div>
                <div className="info-text">
                  <p style={{ color: "#fff" }}>(123) 456 78 90</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="into-icon">
                  <i style={{color: "#fff"}} className="fa fa-envelope-o" />
                </div>
                <div className="info-text">
                  <p style={{ color: "#fff" }}>support@axiomthemes.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-6">
            <span>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              Copyright 2024 © All rights reserved | This template is made with
              <i className="fa fa-heart-o" aria-hidden="true" /> by
              <a href="https://colorlib.com" target="_blank">
                Devopod
              </a>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            </span>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="social-icons">
              <ul>
                <li className="no-margin">Follow Us</li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-pinterest" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
        </Fragment>

    )
}
export default Footer; 