import React, { Fragment } from "react";
import Header from "./Header";
import History from "./history";
import Footer from "./Footer";
import "./history.css";
import Latestproduct from "./latestproduct";
import AboutSec from "./aboutsec";
import Team from "./team";
import Testimonials from "./Testi.js";
import Chilperson from "./chil";


const Aboutus = () => {
  return (
    <Fragment>
       <Header />
     
      
      <>
  
  {/* Header Area End */}
  {/* Banner Area Starts */}
  <section className="banner-area banner-area2 text-center">
 
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 style={{color:"#fff"}}>
            <i>About Us</i>
          </h1>
          <p className="pt-2">
            <i>
              Beast kind form divide night above let moveth bearing darkness.
            </i>
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* Banner Area End */}
  {/* Welcome Area Starts */}
  <section className="welcome-area section-padding2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 align-self-center">
          <div className="welcome-img">
            <img src="images/layer1.png" className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-md-6 align-self-center">
          <div className="welcome-text mt-5 mt-md-0">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              <span className="style-change">
                Spark a revolution on your taste buds!
              </span>
              <br/>
              The muse of a thousand memories
              
              
            </h3>
            <p className="pt-3" style={{color: "#070707"}}>
              Some things feel nostalgic from the moment we leave them.
              Vacations, old friends and college to name a few. And three young
              graduates of Hospitality Management wanted to seal this nostalgia
              in a bottle.
            </p>
            <p style={{color: "#070707"}}>
              Abdul, Aslam and Abhishek set out to make an Indian drink the new
              face of cold refreshment. Their picky palates and intensive market
              research lit the Spark everyone loves today. Spark’s first bottles
              popped in 2018 at select restaurants in Hyderabad. The
              overwhelming public response motivated us to reinvent the
              packaging and make our drink accessible to all
            </p>
            <a href="#" className="template-btn mt-3">
              Read more
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>

  </section>
  {/* Welcome Area End */}
  {/* Deshes Area Starts */}
  <div className="deshes-area section-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-top2 text-center">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              Our <span>Merchandise</span>
            </h3>
            <p style={{color: "#070707"}}>
              <i>
                Beast kind form divide night above let moveth bearing darkness.
              </i>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-md-6 align-self-center">
          <h1>01.</h1>
          <div className="deshes-text">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              <span>Spark</span>
              <br />
              Zeera
            </h3>
            <p className="pt-3" style={{color: "#070707"}}>
              A post-meal companion that leaves a brilliant taste and makes you
              feel at ease. Flavour profile: Blend of zeera and masalas for a
              powerful aroma that cleanses the palate
            </p>
            <span className="style-change">Rp 12.00</span>
            <a href="#" className="template-btn3 mt-3">
              Explore Range
              <span>
                <i className="fa fa-long-arrow-right" />
              </span>
            </a>
          </div>
        </div>
        <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center mt-4 mt-md-0">
          <img src="images/layer2.png" alt="" className="img-fluid" />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-5 col-md-6 align-self-center order-2 order-md-1 mt-4 mt-md-0">
          <img src="images/layer3.png" alt="" className="img-fluid" />
        </div>
        <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center order-1 order-md-2">
          <h1>02.</h1>
          <div className="deshes-text">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              <span>Spark</span>
              <br />
              Orange
            </h3>
            <p className="pt-3" style={{color: "#070707"}}>
              The natural delicious taste of orange translates beautifully as a
              soda too! Flavour profile: Punchy, zesty and sweet.
            </p>
            <span className="style-change">Rp 12.00</span>
            <a href="#" className="template-btn3 mt-3">
              Explore Range
              <span>
                <i className="fa fa-long-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Deshes Area End */}
  {/* Testimonial Area Starts */}
  <Chilperson/>
  <History />

</>

      <Footer/>
     
     
      
     
    
     

   
    </Fragment>
  );
};
export default Aboutus;
