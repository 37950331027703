import logo from './logo.svg';
import './App.css';
import { Fragment } from 'react';
import Home from './Components/Home';
import { Routes,Route } from 'react-router-dom';
import Blog from './Components/Blog';
import Pages from './Components/Page';
import Contact from './Components/Contact';
import ShopingCrat from './Components/ShopingCart';
import ProductCheckout from './Components/ProductCheckout';
import ShopCategory from './Components/Category';
import Conformation from './Components/Conformation';
import Aboutus from './Components/aboutus';
import Footer from './Components/Footer';

function App() {
  return (
   <Fragment>
   
    <Routes>

      <Route path='/' element={<Home/>} />
      <Route exact path='/blog' element={<Blog/>}/>
      <Route exact path='/product_details' element={<Pages/>}/>
      <Route exact path='/contact' element={<Contact/>}/>
      <Route exact path='/Products' element={<ShopingCrat/>}/>
      <Route exact path='/proceed_checkout' element={<ProductCheckout/>}/>
      <Route exact path='/categories' element={<ShopCategory/>}/>
      <Route exact path='/confirmation' element={<Conformation/>}/>
      <Route exact path='/about_us' element={<Aboutus/>}/>
      
    </Routes>
   

   </Fragment>
  );
}

export default App;
