import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Chilperson = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <section className="testimonial text-center">
    <div className="container">
      <div className="heading white-heading">The Most chill persons in the room</div>
      <Slider {...settings}>
        <div className="testimonial4_slide">
          <img
            src="https://i.ibb.co/8x9xK4H/team.jpg"
            className="img-circle img-responsive"
            alt="team"
          />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
           
          </p>
        </div>
        <div className="testimonial4_slide">
          <img
            src="https://i.ibb.co/8x9xK4H/team.jpg"
            className="img-circle img-responsive"
            alt="team"
          />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            
          </p>
        </div>
        <div className="testimonial4_slide">
          <img
            src="https://i.ibb.co/8x9xK4H/team.jpg"
            className="img-circle img-responsive"
            alt="team"
          />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
           
          </p>
        </div>
      </Slider>
    </div>
  </section>
  );
};
export default Chilperson;
