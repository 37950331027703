import React, { useState, Fragment } from "react";
import "./header.css";

import { GiHamburgerMenu } from "react-icons/gi";

import { Link } from "react-router-dom";

const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  return (
    <Fragment>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
          <img
            src="images/logo/spark.png"
            alt="Sparks Logo"
          
          />
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li >
              <Link to="/" style={{color:"#fff",fontFamily:"Playfair Display, serif" ,}} >Home</Link>
            </li>            
            <li>
              <Link to="/products" style={{color:"#fff",}}>Products</Link>
            </li>
            <li>
              <Link to="/about_us" style={{color:"#fff",}}>About</Link>
            </li>
            <li>
              <Link to="/blog" style={{color:"#fff",}}>Blog</Link>
            </li>
            <li>
              <Link to="/contact" style={{color:"#fff",}}>Contact</Link>
            </li>
            <li>
              <Link to="/shopingcart" style={{color:"#fff",}}>Cart Home</Link>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          {/* <ul className="social-media-desktop">
            <li>
              <a
                href="https://www.youtube.com/channel/UCwfaAHy4zQUb2APNOGXUCCA"
                target="_thapa">
                <FaFacebookSquare className="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/thapatechnical/"
                target="_thapa">
                <FaInstagramSquare className="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCwfaAHy4zQUb2APNOGXUCCA"
                target="_thapa">
                <FaYoutubeSquare className="youtube" />
              </a>
            </li>
          </ul> */}

          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
