import React, { Fragment,useEffect } from "react";
import "./history.css";
import Aos from "aos";
import "aos/dist/aos.css";


const History = () => {
  useEffect(()=>{
    Aos.init();
  })
  return (
    <Fragment>
      <section className="blog_categorie_area">
        <div className="roadmap-container">
          <div className="roadmap-auto">
            <div data-aos="fade-up">
            <h2 className="roadmap-h2">History of Goli Soda</h2>
            </div>
            <div className="spacer-block">&nbsp;</div>
            <div className="roadmap-timeline">
              <div className="timeline-dot">&nbsp;</div>
              <div className="timeline-list">
                <div className="timeline-list-vr">&nbsp;</div>
                <div className="timeline-list-col timeline-col-left">
                  <div className="timeline-item-block timeline-item-block-green" data-aos="fade-right">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2016&nbsp;</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">Starting Up</div>
                    <div className="timeline-item-content">
                      <p>Research on aviation market</p>
                      <p>Research on cryptocurrency</p>
                      <p>
                        Research on traditional online payment system &amp;
                        alternative payment solution
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block-mobile timeline-item-block-violet" data-aos="fade-right">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2017 Q1</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">
                      Buiding Up And Getting Real Experiences
                    </div>
                    <div className="timeline-item-content">
                      <p>
                        Integrate Bitair payment solution into real business
                        network regarding booking airline ticket (trial version)
                        to conduct research
                      </p>
                      <p>Make survey of user's experiences &amp; choices</p>
                      <p>
                        Approach airlines, travel &amp; tourism agencies
                        regarding a new, fast, cheap, reliable payment method.
                      </p>
                      <p>Develop application iOS and Android</p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block-blue" data-aos="fade-right">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2017 Q2</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">
                      Opening New Business
                    </div>
                    <div className="timeline-item-content">
                      <p>
                        Finish necessarily legal procedure to able to operate
                        across many regions / nations
                      </p>
                      <p>Fulfill human requirement and head-hunting work</p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block-mobile timeline-item-block-green2" data-aos="fade-right">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2017 Q3</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">ICO and IPO</div>
                    <div className="timeline-item-content">
                      <p>Late August: Presale period</p>
                      <p>Token sale Event</p>
                      <p>September: Initial Coin Offering (ICO) period</p>
                      <p>
                        Mid-September: Prepare for initial Public Offering (IPO)
                        period
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block17q4 timeline-item-block-green" data-aos="fade-right">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2017 Q4</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">
                      LISTING BTCA ON EXCHANGES
                    </div>
                    <div className="timeline-item-content">
                      <p>
                        Early October, prepare full project to submit BTCA to
                        Exchanges.
                      </p>
                      <p>
                        Target to list on other top Exchanges such as: Hitbtc
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block-mobile timeline-item-block-blue" data-aos="fade-left">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2018 Q1</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">
                      Globalizing Bitair System
                    </div>
                    <div className="timeline-item-content">
                      <p className="no-bullet">
                        Reach agreement on deploying Bitair payment solution via
                        top ten chosen global websites such as: Expedia.com,
                        Travelport.com, Cheapadvisor.com, Booking.com, Agoda.com
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block18q2 timeline-item-block-violet" data-aos="fade-right">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2018 Q2</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">And Further</div>
                    <div className="timeline-item-content">
                      <p>
                        Strengthen the partnership and enhance marketing
                        campaign.
                      </p>
                      <p>Distribute token revenue and holder income.</p>
                    </div>
                  </div>
                </div>
                <div className="timeline-list-col timeline-col-right">
                  <div className="timeline-item-block timeline-item-block-right timeline-item-block-violet" data-aos="fade-left">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2017 Q1</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">
                      Buiding Up And Getting Real Experiences
                    </div>
                    <div className="timeline-item-content">
                      <p>
                        Integrate Bitair payment solution into real business
                        network regarding booking airline ticket (trial version)
                        to conduct research
                      </p>
                      <p>Make survey of user's experiences &amp; choices</p>
                      <p>
                        Approach airlines, travel &amp; tourism agencies
                        regarding a new, fast, cheap, reliable payment method.
                      </p>
                      <p>Develop application iOS and Android</p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block-right timeline-item-block-green2" data-aos="fade-left">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2017 Q3</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">ICO and IPO</div>
                    <div className="timeline-item-content">
                      <p>Late August: Presale period</p>
                      <p>Token sale Event</p>
                      <p>September: Initial Coin Offering (ICO) period</p>
                      <p>
                        Mid-September: Prepare for initial Public Offering (IPO)
                        period
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item-block timeline-item-block-right timeline-item-block-blue" data-aos="fade-left">
                    <div className="timeline-item-time">
                      <div className="timeline-item-label">2018 Q1</div>
                    </div>
                    <div className="timeline-item-label-hr">
                      <div className="item-dot timeline-dot">&nbsp;</div>
                    </div>
                    <div className="timeline-item-label2">
                      Globalizing Bitair System
                    </div>
                    <div className="timeline-item-content">
                      <p className="no-bullet">
                        Reach agreement on deploying Bitair payment solution via
                        top ten chosen global website
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default History;
