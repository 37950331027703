import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";


const Contact=()=>{
    return(
        <Fragment>
           
          
            <section className="banner-area banner-area2 contact-bg text-center">
            <Header/>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 style={{color:"#fff"}}>
            <i>Contact Us</i>
          </h1>
          <p className="pt-2">
            <i>
              Beast kind form divide night above let moveth bearing darkness.
            </i>
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* Banner Area End */}
  {/* Map Area Starts */}
  {/* <section class="table-area section-padding" style="border-radius: ;">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="section-top2 text-center">
                  <h3>Book <span>your</span> table</h3>
                  <p><i>Beast kind form divide night above let moveth bearing darkness.</i></p>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-8 offset-lg-2">
              <form action="#">
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                      </div>
                      <input type="text" id="datepicker">
                  </div>
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>
                      <input type="text" id="datepicker2">
                  </div>
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-user-o"></i></span>
                      </div>
                      <input type="text">
                  </div>
                  <div class="table-btn text-center">
                      <a href="#" class="template-btn template-btn2 mt-4">book a table</a>
                  </div>
              </form>
          </div>
      </div>
  </div>
    </section> */}
  {/* Map Area End */}
  {/* Contact Form Starts */}
  <section
    className="contact-form section-padding3"
    style={{ marginTop: "2rem" }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-3 mb-5 mb-lg-0">
          <div className="d-flex">
            <div className="into-icon">
              <i className="fa fa-home" />
            </div>
            <div className="info-text">
              <h5>Telegana</h5>
              <p>Hyderabad</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="into-icon">
              <i className="fa fa-phone" />
            </div>
            <div className="info-text">
              <h5>00 (440) 9865 562</h5>
              <p>Mon to Fri 9am to 6 pm</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="into-icon">
              <i className="fa fa-envelope-o" />
            </div>
            <div className="info-text">
              <h5>support@colorlib.com</h5>
              <p>Send us your query anytime!</p>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <form action="#">
            <div className="left">
              <input
                type="text"
                placeholder="Enter your name"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Enter your name'"
                required=""
              />
              <input
                type="email"
                placeholder="Enter email address"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Enter email address'"
                required=""
              />
              <input
                type="text"
                placeholder="Enter subject"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Enter subject'"
                required=""
              />
            </div>
            <div className="right">
              <textarea
                name="message"
                cols={20}
                rows={7}
                placeholder="Enter Message"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Enter Message'"
                required=""
                defaultValue={""}
              />
            </div>
            <button type="submit" className="template-btn">
              subscribe now
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
  {/*================Contact Area =================*/}
  {/* start footer Area */}
  <Footer/>


        </Fragment>
    )

}
export default Contact;