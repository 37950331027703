import React, { Fragment, useEffect , useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import CountUp from 'react-countup';
import {remixProducts} from './dataSources';
const Home = () => {
  useEffect(() => {
    Aos.init();
  });
  
  return (
    <>
   <Header/>
  {/* Header Area End */}
  {/* Banner Area Starts */}
  <section className="main-banner" id="home">
    <div className="hero-banner">
      <video autoPlay loop muted poster="images/video-poster.jpg">
        <source src="images/blog/Home page.mp4" type="video/mp4" />
      </video>
      <div className="hero-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-text">
                
                <div className="banner-btn mt-4">
                  {/* Add any buttons or controls here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  {/* Banner Area End */}

 
  {/* Food Area starts */}
  <section className="food-area ">
    <div className="container text-center">
      <div className="row">
        <div className="col-md-12">
          <div className="section-top">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              <span className="style-change" >Our Remix</span> Products
             
            </h3>
            <p className="pt-3" style={{color: "#070707"}}>
              Stick to the classics or Spark something new!
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        {remixProducts.map((product)=>(
            <div className="col-md-4 col-sm-6" style={{ marginBottom:"1rem"}}>
          <div className="single-food">
            <div className="food-img">
              <img
                src={product.imageUrl}
                className="img-fluid main-image"
                alt=""
                style={{ maxWidth: "16%", marginLeft: "0rem" }}
              />
              {/* Add the GIF for this image directly */}
              <img src={product.gifUrl} className="gif" alt="GIF" />
            </div>
            <div className="food-content">
              <div className="">
                <h5 style={{ color: product.color }}>{product.name}:</h5>
                <p style={{ marginLeft: "1rem", color:"#070707" }}>
                 {product.description}
                  
                </p>
                <span className="style-change" />
              </div>
              <h6
                style={{
                  color: product.color,
                  marginLeft: "0rem",
                  fontSize: "17px !important"
                }}
              >
                Flavour profile:
              </h6>
              <p style={{ marginLeft: "0rem",color:"#070707" }}>{product.flavour}</p>
              <a
                href={product.href}
                className="template-btn mt-3"
                style={{ marginLeft: "0rem" }}
              >
                Add to Cart
              </a>
            </div>
          </div>
        </div>
         ))}

      </div>
    </div>
  </section>
  {/* Food Area End */}
  {/* Reservation Area Starts */}
  
  <div className="reservation-area section-padding text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2 style={{color:"#fff"}}>Premium Goli Soda</h2>
          <h4 className="mt-4" style={{color:"#fff"}}>
            100% natural ingredients 6 flavours to choose from
          </h4>
          <a href="" className="template-btn template-btn2 mt-4" >
            Pan-India Delivery
          </a>
        </div>
      </div>
    </div>
  </div> 
  {/* Reservation Area End */}
  {/* Deshes Area Starts */}

  <div className="deshes-area section-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-top2 text-center">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              Our <span>Merchandise</span>
            </h3>
            <p style={{color: "#070707"}}>
              <i>
                Beast kind form divide night above let moveth bearing darkness.
              </i>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-md-6 align-self-center">
          <h1>01.</h1>
          <div className="deshes-text">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              <span>Spark</span>
              <br />
              Zeera
            </h3>
            <p className="pt-3" style={{color: "#070707"}}>
              A post-meal companion that leaves a brilliant taste and makes you
              feel at ease. Flavour profile: Blend of zeera and masalas for a
              powerful aroma that cleanses the palate
            </p>
            <span className="style-change">Rp 12.00</span>
            <a href="#" className="template-btn3 mt-3">
              Explore Range
              <span>
                <i className="fa fa-long-arrow-right" />
              </span>
            </a>
          </div>
        </div>
        <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center mt-4 mt-md-0">
          <img src="images/layer2.png" alt="" className="img-fluid" />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-5 col-md-6 align-self-center order-2 order-md-1 mt-4 mt-md-0">
          <img src="images/layer3.png" alt="" className="img-fluid" />
        </div>
        <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center order-1 order-md-2">
          <h1>02.</h1>
          <div className="deshes-text">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              <span>Spark</span>
              <br />
              Orange
            </h3>
            <p className="pt-3" style={{color: "#070707"}}>
              The natural delicious taste of orange translates beautifully as a
              soda too! Flavour profile: Punchy, zesty and sweet.
            </p>
            <span className="style-change">Rp 12.00</span>
            <a href="#" className="template-btn3 mt-3">
              Explore Range
              <span>
                <i className="fa fa-long-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Deshes Area End */}
  {/* Testimonial Area Starts */}
 {/* <Chilperson/> */} 
  {/* <section className="testimonial text-center">
    <div className="container">
      <div className="heading white-heading">
        The Most chill persons in the room
      </div>
      <div
        id="testimonial4"
        className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
        data-ride="carousel"
        data-pause="hover"
        data-interval={5000}
        data-duration={2000}
      >
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <div className="testimonial4_slide">
              <img
                src="https://i.ibb.co/8x9xK4H/team.jpg"
                className="img-circle img-responsive"
              />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
             
            </div>
          </div>
          <div className="carousel-item">
            <div className="testimonial4_slide">
              <img
                src="https://i.ibb.co/8x9xK4H/team.jpg"
                className="img-circle img-responsive"
              />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              
            </div>
          </div>
          <div className="carousel-item">
            <div className="testimonial4_slide">
              <img
                src="https://i.ibb.co/8x9xK4H/team.jpg"
                className="img-circle img-responsive"
              />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
             
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#testimonial4"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" />
        </a>
        <a
          className="carousel-control-next"
          href="#testimonial4"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" />
        </a>
      </div>
    </div>
    
  </section> */}
  {/* Testimonial Area End */}
  {/* Update Area Starts */}
  <section className="update-area section-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-top2 text-center">
            <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
              Our <span>Spark</span> update
            </h3>
            <p style={{color: "#070707"}}>
              <i>
                Beast kind form divide night above let moveth bearing darkness.
              </i>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="single-food">
            <div className="food-img">
              <img src="images/story.jpg" className="img-fluid" alt="" />
            </div>
            <div className="food-content">
              <div className="post-admin d-lg-flex mb-3">
                <span className="mr-5 d-block mb-2 mb-lg-0">
                  <i className="fa fa-user-o mr-2" />
                  Admin
                </span>
                <span>
                  <i className="fa fa-calendar-o mr-2" />
                  18/09/2018
                </span>
              </div>
              <h5>no finer food can be found</h5>
              <p style={{color: "#070707"}}>
                Now you can order your favourite flavours of Spark with just a
                few clicks. But this legendary drink has humbler roots. Read on
                to know how Goli Soda came into the picture, briefly took a
                break and is now
              </p>
              <a href="#" className="template-btn3 mt-2">
                read more{" "}
                <span>
                  <i className="fa fa-long-arrow-right" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-food my-5 my-md-0">
            <div className="food-img">
              <img src="images/story.jpg" className="img-fluid" alt="" />
            </div>
            <div className="food-content">
              <div className="post-admin d-lg-flex mb-3">
                <span className="mr-5 d-block mb-2 mb-lg-0">
                  <i className="fa fa-user-o mr-2" />
                  Admin
                </span>
                <span>
                  <i className="fa fa-calendar-o mr-2" />
                  20/09/2018
                </span>
              </div>
              <h5>things go better with food</h5>
              <p style={{color: "#070707"}}>
                Now you can order your favourite flavours of Spark with just a
                few clicks. But this legendary drink has humbler roots. Read on
                to know how Goli Soda came into the picture, briefly took a
                break and is now
              </p>
              <a href="#" className="template-btn3 mt-2">
                read more{" "}
                <span>
                  <i className="fa fa-long-arrow-right" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-food">
            <div className="food-img">
              <img src="images/story.jpg" className="img-fluid" alt="" />
            </div>
            <div className="food-content">
              <div className="post-admin d-lg-flex mb-3">
                <span className="mr-5 d-block mb-2 mb-lg-0">
                  <i className="fa fa-user-o mr-2" />
                  Admin
                </span>
                <span>
                  <i className="fa fa-calendar-o mr-2" />
                  22/09/2018
                </span>
              </div>
              <h5>food head above the rest</h5>
              <p style={{color: "#070707"}}>
                Now you can order your favourite flavours of Spark with just a
                few clicks. But this legendary drink has humbler roots. Read on
                to know how Goli Soda came into the picture, briefly took a
                break and is now
              </p>
              <a href="#" className="template-btn3 mt-2">
                read more{" "}
                <span>
                  <i className="fa fa-long-arrow-right" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="update-area section-padding" style={{ marginTop: "-13rem" }}>
      <div className="section-top2 text-center">
        <h3 style={{color:"#131230", fontFamily:"Playfair Display, serif", fontSize:"36px !imporant", fontWeight:"600"}}>
          Our <span>Spark</span> Achievements
        </h3>
        <p style={{color: "#070707"}}>
          <i>Beast kind form divide night above let moveth bearing darkness.</i>
        </p>
      </div>
      <div id="counter">
        <div className="item">
          <CountUp  style={{fontFamily:"Playfair Display, serif", fontSize:"3rem", fontWeight:"600",color: "#ffa501"}}start={0} end={200} duration={2} separator="," decimals={0} suffix="" />
          <h3 className="text" style={{color:"#131230",fontFamily:"Playfair Display, serif",fontWeight:"600"}}>Reward</h3>
        </div>
        <div className="item">
          <CountUp style={{fontFamily:"Playfair Display, serif", fontSize:"3rem", fontWeight:"600",color: "#ffa501"}}start={0} end={150} duration={2} separator="," decimals={0} suffix="" />
          <h3 className="text" style={{color:"#131230",fontFamily:"Playfair Display, serif",fontWeight:"600"}}>year</h3>
        </div>
        <div className="item">
          <CountUp style={{fontFamily:"Playfair Display, serif", fontSize:"3rem", fontWeight:"600",color: "#ffa501"}} start={0} end={250} duration={2} separator="," decimals={0} suffix="" />
          <h3 className="text"style={{color:"#131230",fontFamily:"Playfair Display, serif",fontWeight:"600"}}>Happy</h3>
        </div>
      </div>
    </section>
  {/* Footer Area Starts */}
  <Footer/>

</>

  );
};
export default Home;
