import React, { Fragment,useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
//import { Link } from "react-router-dom";
import Aos from "aos";
import 'aos/dist/aos.css';



const ShopCategory = () => {
  useEffect(()=>{
    Aos.init();
  })
  return (
    <Fragment>
      <Header />
      {/* End Header Area */}
      {/* Start Banner Area */}
      <section className="banner-area organic-banner-img" data-aos="fade-right">
        <div className="container">
          <div className="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
            <div className="col-first" data-aos="fade-right">
              <h1>Shop Category </h1>
              <nav className="d-flex align-items-center">
                <a href="index.html">
                  Home
                  <span className="lnr lnr-arrow-right" />
                </a>
                <a href="#">
                  Shop
                  <span className="lnr lnr-arrow-right" />
                </a>
                <a href="category.html">Fashon Category</a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* End Banner Area */}
      <div className="container">
        <div className="row" data-aos="fade-right">
          <div className="col-xl-3 col-lg-4 col-md-5">
            <div className="sidebar-categories">
              <div className="head">Browse Categories</div>
              <ul className="main-categories">
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#fruitsVegetable"
                    aria-expanded="false"
                    aria-controls="fruitsVegetable"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Fruits and Vegetables<span className="number">(53)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="fruitsVegetable"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="fruitsVegetable"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#meatFish"
                    aria-expanded="false"
                    aria-controls="meatFish"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Meat and Fish<span className="number">(53)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="meatFish"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="meatFish"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#cooking"
                    aria-expanded="false"
                    aria-controls="cooking"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Cooking<span className="number">(53)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="cooking"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="cooking"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#beverages"
                    aria-expanded="false"
                    aria-controls="beverages"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Beverages<span className="number">(24)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="beverages"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="beverages"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#homeClean"
                    aria-expanded="false"
                    aria-controls="homeClean"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Home and Cleaning<span className="number">(53)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="homeClean"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="homeClean"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a href="#">
                    Pest Control<span className="number">(24)</span>
                  </a>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#officeProduct"
                    aria-expanded="false"
                    aria-controls="officeProduct"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Office Products<span className="number">(77)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="officeProduct"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="officeProduct"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#beauttyProduct"
                    aria-expanded="false"
                    aria-controls="beauttyProduct"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Beauty Products<span className="number">(65)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="beauttyProduct"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="beauttyProduct"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#healthProduct"
                    aria-expanded="false"
                    aria-controls="healthProduct"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Health Products<span className="number">(29)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="healthProduct"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="healthProduct"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a href="#">
                    Pet Care<span className="number">(29)</span>
                  </a>
                </li>
                <li className="main-nav-list">
                  <a
                    data-toggle="collapse"
                    href="#homeAppliance"
                    aria-expanded="false"
                    aria-controls="homeAppliance"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Home Appliances<span className="number">(15)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="homeAppliance"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="homeAppliance"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="main-nav-list">
                  <a
                    className="border-bottom-0"
                    data-toggle="collapse"
                    href="#babyCare"
                    aria-expanded="false"
                    aria-controls="babyCare"
                  >
                    <span className="lnr lnr-arrow-right" />
                    Baby Care<span className="number">(48)</span>
                  </a>
                  <ul
                    className="collapse"
                    id="babyCare"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="babyCare"
                  >
                    <li className="main-nav-list child">
                      <a href="#">
                        Frozen Fish<span className="number">(13)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Dried Fish<span className="number">(09)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Fresh Fish<span className="number">(17)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#">
                        Meat Alternatives<span className="number">(01)</span>
                      </a>
                    </li>
                    <li className="main-nav-list child">
                      <a href="#" className="border-bottom-0">
                        Meat<span className="number">(11)</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="sidebar-filter mt-50">
              
              
              <div className="common-filter">
             
                
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-7">
            {/* Start Filter Bar */}
            <div className="filter-bar d-flex flex-wrap align-items-center">
              {/* <div className="sorting">
                <select>
                  <option value={1}>Default sorting</option>
                  <option value={1}>Default sorting</option>
                  <option value={1}>Default sorting</option>
                </select>
              </div> */}
              {/* <div className="sorting mr-auto">
                <select>
                  <option value={1}>Show 12</option>
                  <option value={1}>Show 12</option>
                  <option value={1}>Show 12</option>
                </select>
              </div> */}
              <div className="pagination">
                <a href="#" className="prev-arrow">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                </a>
                <a href="#" className="active">
                  1
                </a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#" className="dot-dot">
                  <i className="fa fa-ellipsis-h" aria-hidden="true" />
                </a>
                <a href="#">6</a>
                <a href="#" className="next-arrow">
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </a>
              </div>
            </div>
            {/* End Filter Bar */}
            {/* Start Best Seller */}
            <section className="lattest-product-area pb-40 category-list">
              <div className="row" data-aos="fade-up">
                {/* single product */}
                <div className="col-lg-4 col-md-6">
                  <div className="single-product-img-fluid1">
                    <img
                      className="img-fluid"
                      src="img/product/p2.png"
                      alt=""
                    />
                    <div className="product-details">
                      <h6>Spark cherry goli</h6>
                      <div className="price">
                        <h6>$150</h6>
                        <button class="button-29" role="button">
                          Add cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single product */}
                <div className="col-lg-4 col-md-6">
                  <div className="single-product-img-fluid">
                    <img
                      className="img-fluid"
                      src="img/product/p2.png"
                      alt=""
                    />
                    <div className="product-details">
                      <h6>Spark cherry goli</h6>
                      <div className="price">
                        <h6>$150</h6>
                        <button class="button-29" role="button">
                          Add cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single product */}
                <div className="col-lg-4 col-md-6">
                  <div className="single-product-img-fluid1">
                    <img
                      className="img-fluid"
                      src="img/product/10.png"
                      alt=""
                    />
                    <div className="product-details">
                      <h6>Spark cherry goli</h6>
                      <div className="price">
                        <h6>$150</h6>
                        <button class="button-29" role="button">
                          Add cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single product */}
                <div className="col-lg-4 col-md-6">
                  <div className="single-product-img-fluid1">
                    <img
                      className="img-fluid"
                      src="img/product/8.png"
                      alt=""
                    />
                    <div className="product-details">
                      <h6>Spark cherry goli</h6>
                      <div className="price">
                        <h6>$150</h6>
                        <button class="button-29" role="button">
                          Add cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single product */}
                <div className="col-lg-4 col-md-6">
                  <div className="single-produc-img-fluid1t">
                    <img
                      className="img-fluid"
                      src="img/product/7.png"
                      alt=""
                    />
                    <div className="product-details">
                      <h6>Spark cherry goli</h6>
                      <div className="price">
                        <h6>$150</h6>
                        <button class="button-29" role="button">
                          Add cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single product */}
                <div className="col-lg-4 col-md-6">
                  <div className="single-product-img-fluid1">
                    <img
                      className="img-fluid"
                      src="img/product/p2.png"
                      alt=""
                    />
                    <div className="product-details">
                      <h6>Spark cherry goli</h6>
                      <div className="price">
                        <h6>$150</h6>
                        <button class="button-29" role="button">
                          Add cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Best Seller */}
            {/* Start Filter Bar */}
            <div className=" d-flex flex-wrap align-items-center">
              
            </div>
            {/* End Filter Bar */}
          </div>
        </div>
      </div>
      {/* Start related-product Area */}

      <Footer />
    </Fragment>
  );
};
export default ShopCategory;
