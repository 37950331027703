export const products=[{
  sku:'001',
  name:'Orange',
  imageUrl:'images/Oange.png',
  href:'',
  description:'The natural delicious taste of orange translates beautifully as a soda too!',
  flavour:'Punchy, zesty and sweet.',
  gifUrl:'images/Orange_2.gif',
  color:'orange'

},{
sku:'002',
  name:'Guva',
  imageUrl:'images/Guava.png',
  href:'',
  description:'A tropical treat now hits the streets! One of our bolder flavours is sure to thrill you.',
  flavour:'Tangy, acidic and earthy',
  gifUrl:'images/Guava_2.gif',
  color:'rgb(251, 38, 63)'

},
{sku:'003',
  name:'blueberry',
  imageUrl:'images/blueberry.png',
  href:'',
  description:'An icon of the dessert world reimagined as an iconic drink.',
  flavour:'Acidic, mildly sweet with floral notes',
  gifUrl:'images/blueberry_2.gif',
  color:'rgb(38, 205, 251)'

},
{sku:'004',
  name:'Zeera',
  imageUrl:'images/Jeera.png',
  href:'',
  description:'A post-meal companion that leaves a brilliant taste and makes you feel at ease.',
  flavour:'Blend of zeera and masalas for a powerful aroma that cleanses the palate',
  gifUrl:'images/Zeera.gif',
  color:'#8b4513'

},
{sku:'005',
  name:'Lemojin',
  imageUrl:'images/Lemogin_img.png',
  href:'',
  description:'The OG authentic Goli Soda flavour we all love and missed.',
  flavour:'A burst of freshness, citrus and a subtle saltiness for balance',
  gifUrl:'images/lemojin_2.gif',
  color:'rgb(249, 213, 52)'

}];


export const remixProducts=[{
  sku:'0011',
  name:'Cherry Cola',
  imageUrl:'images/remix/Cherry Cola.png',
  href:'',
  description:'The natural delicious taste of orange translates beautifully as a soda too!',
  flavour:'Punchy, zesty and sweet.',
  gifUrl:'images/remix/Cherry_cola_3.gif',
  color:'rgb(249, 65, 65)'

},{
  sku:'0012',
  name:'Citus Blast',
  imageUrl:'images/remix/Citrus blast.png',
  href:'',
  description:'A tropical treat now hits the streets! One of our bolder flavours is sure to thrill you.',
  flavour:' Tangy, acidic and earthy',
  gifUrl:'images/remix/Citrus_Blast_2.gif',
  color:'orange'

},{
  sku:'0013',
  name:'Coco Berry',
  imageUrl:'images/remix/Coco Berry.png',
  href:'',
  description:'An icon of the dessert world reimagined as an iconic drink.',
  flavour:' Acidic, mildly sweet with floral notes',
  gifUrl:'images/remix/Coco_berry_2.gif',
  color:'rgb(38, 205, 251)'

},{
  sku:'0014',
  name:'Coco Berry',
  imageUrl:'images/remix/Green Twister.png',
  href:'',
  description:' A post-meal companion that leaves a brilliant taste and makes you feel at ease.',
  flavour:' Blend of zeera and masalas for a powerful aroma that cleanses the palate',
  gifUrl:'images/remix/Green Twister.gif',
  color:'#00fe22'

},{
  sku:'0015',
  name:'Peach Punch',
  imageUrl:'images/remix/Peach punch.png',
  href:'',
  description:'The OG authentic Goli Soda flavour we all love and missed.',
  flavour:'A burst of freshness, citrus and a subtle saltiness for balance.',
  gifUrl:'images/remix/peach_punch_2.gif',
  color:'rgb(195, 93, 3)'

},{
  sku:'0016',
  name:'Straberry Margarita',
  imageUrl:'images/remix/straberry margarita.png',
  href:'',
  description:'The OG authentic Goli Soda flavour we all love and missed.',
  flavour:'A burst of freshness, citrus and a subtle saltiness for balance.',
  gifUrl:'images/remix/Strawberry_margargarate_2.gif',
  color:'rgb(183, 4, 4)'

}];


