import React, { Fragment, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Testimonials from "./Testi";
import {remixProducts, products} from './dataSources';

const ShopingCrat = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <Fragment>
      <section className="banner-area banner-area2 text-center">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ color: "#fff" }}>
                <i>Products</i>
              </h1>
              <p className="pt-2">
                <i>Stick to the classics or Spark something new!</i>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Area End */}
      {/* Welcome Area Starts */}
   
      {/* Welcome Area End */}
      {/* Deshes Area Starts */}
      <section className="food-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="section-top">
                <h3
                  style={{
                    color: "#131230",
                    fontFamily: "Playfair Display, serif",
                    fontSize: "36px !imporant",
                    fontWeight: "600",
                  }}
                >
                  <span className="style-change">Our Regular</span>  
                  Products
                </h3>
                <p className="pt-3" style={{ color: "#070707" }}>
                  Stick to the classics or Spark something new!
                </p>
              </div>
            </div>
          </div>
          <div className="row">
        {products.map((product)=>(
            <div className="col-md-4 col-sm-6" style={{ marginBottom:"1rem"}}>
          <div className="single-food">
            <div className="food-img">
              <img
                src={product.imageUrl}
                className="img-fluid main-image"
                alt=""
                style={{ maxWidth: "16%", marginLeft: "0rem" }}
              />
              {/* Add the GIF for this image directly */}
              <img src={product.gifUrl} className="gif" alt="GIF" />
            </div>
            <div className="food-content">
              <div className="">
                <h5 style={{ color: product.color }}>{product.name}:</h5>
                <p style={{ marginLeft: "1rem", color:"#070707" }}>
                 {product.description}
                  
                </p>
                <span className="style-change" />
              </div>
              <h6
                style={{
                  color: product.color,
                  marginLeft: "0rem",
                  fontSize: "17px !important"
                }}
              >
                Flavour profile:
              </h6>
              <p style={{ marginLeft: "0rem",color:"#070707" }}>{product.flavour}</p>
              <a
                href={product.href}
                className="template-btn mt-3"
                style={{ marginLeft: "0rem" }}
              >
                Add to Cart
              </a>
            </div>
          </div>
        </div>
         ))}

      </div>
        </div>
      </section>
      <section
        className="food-area section-padding"
        style={{ paddingTop: "0rem" }} >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="section-top">
                <h3
                  style={{
                    color: "#131230",
                    fontFamily: "Playfair Display, serif",
                    fontSize: "36px !imporant",
                    fontWeight: "600",
                  }}
                >
                  <span className="style-change">Our Remix</span> Products
                </h3>
                <p className="pt-3" style={{ color: "#070707" }}>
                  Stick to the classics or Spark something new!
                </p>
              </div>
            </div>
          </div>
         <div className="row">
        {remixProducts.map((product)=>(
            <div className="col-md-4 col-sm-6" style={{ marginBottom:"1rem"}}>
          <div className="single-food">
            <div className="food-img">
              <img
                src={product.imageUrl}
                className="img-fluid main-image"
                alt=""
                style={{ maxWidth: "16%", marginLeft: "0rem" }}
              />
              {/* Add the GIF for this image directly */}
              <img src={product.gifUrl} className="gif" alt="GIF" />
            </div>
            <div className="food-content">
              <div className="">
                <h5 style={{ color: product.color }}>{product.name}:</h5>
                <p style={{ marginLeft: "1rem", color:"#070707" }}>
                 {product.description}
                  
                </p>
                <span className="style-change" />
              </div>
              <h6
                style={{
                  color: product.color,
                  marginLeft: "0rem",
                  fontSize: "17px !important"
                }}
              >
                Flavour profile:
              </h6>
              <p style={{ marginLeft: "0rem",color:"#070707" }}>{product.flavour}</p>
              <a
                href={product.href}
                className="template-btn mt-3"
                style={{ marginLeft: "0rem" }}
              >
                Add to Cart
              </a>
            </div>
          </div>
        </div>
         ))}

      </div>
        </div>
      </section>
      {/* Deshes Area End */}
      {/* Testimonial Area Starts */}
      <section className="food-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="section-top">
                <h3
                  style={{
                    color: "#131230",
                    fontFamily: "Playfair Display, serif",
                    fontSize: "36px !imporant",
                    fontWeight: "600",
                  }}
                >
                  <span className="style-change">Customer</span> Reviews
                </h3>
                <p className="pt-3" style={{ color: "#070707" }}>
                  Do not take our word for it, see what clients say!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Testimonials />

      <Footer />
    </Fragment>
  );
};
export default ShopingCrat;
