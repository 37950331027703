import React, { Fragment, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import History from "./history";

const Blog = () => {
  useEffect(() => {
    Aos.init();
  });

  return (
    <Fragment>
      <div>
       
        {/* End Header Area */}
        {/* Start Banner Area */}
        <section className="banner-area banner-area2 text-center">
            <Header/>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 style={{color:"#fff"}}>
            <i>Blog</i>
          </h1>
          <p className="pt-2">
            <i>Stick to the classics or Spark something new!</i>
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* Banner Area End */}
  {/*================Blog Categorie Area =================*/}
  <section className="blog_categorie_area">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <div className="categories_post">
            <img src="images/blog/cat-post/cat-post-3.jpg" alt="post" />
            <div className="categories_details">
              <div className="categories_text">
                <a href="blog-details.html">
                  <h5>Social Life</h5>
                </a>
                <div className="border_line" />
                <p >Enjoy your social life together</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <div className="categories_post">
            <img src="images/blog/cat-post/cat-post-2.jpg" alt="post" />
            <div className="categories_details">
              <div className="categories_text">
                <a href="blog-details.html">
                  <h5>Politics</h5>
                </a>
                <div className="border_line" />
                <p>Be a part of politics</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="categories_post">
            <img src="images/blog/cat-post/cat-post-1.jpg" alt="post" />
            <div className="categories_details">
              <div className="categories_text">
                <a href="blog-details.html">
                  <h5>Food</h5>
                </a>
                <div className="border_line" />
                <p>Let the food be finished</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*================Blog Categorie Area =================*/}
  {/*================Blog Area =================*/}
  <section className="blog_area">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="blog_left_sidebar">
            <article className="row blog_item">
              <div className="col-md-3">
                <div className="blog_info text-right">
                  <div className="post_tag">
                    <a href="#">Food,</a>
                    <a className="active" href="#">
                      Technology,
                    </a>
                    <a href="#">Politics,</a>
                    <a href="#">Lifestyle</a>
                  </div>
                  <ul className="blog_meta list">
                    <li>
                      <a href="#">
                        Mark wiens
                        <i className="fa fa-user-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        12 Dec, 2017
                        <i className="fa fa-calendar-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        1.2M Views
                        <i className="fa fa-eye" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        06 Comments
                        <i className="fa fa-comment-o" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="blog_post">
                  <img src="images/blog/main-blog/m-blog-1.jpg" alt="" />
                  <div className="blog_details">
                    <a href="blog-details.html">
                      <h4>Astronomy Binoculars A Great Alternative</h4>
                    </a>
                    <p style={{color: "#070707"}}>
                      MCSE boot camps have its supporters and its detractors.
                      Some people do not understand why you should have to spend
                      money on boot camp when you can get the MCSE study
                      materials yourself at a fraction.
                    </p>
                    <a href="blog-details.html" className="template-btn">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </article>
            <article className="row blog_item">
              <div className="col-md-3">
                <div className="blog_info text-right">
                  <div className="post_tag">
                    <a href="#">Food,</a>
                    <a className="active" href="#">
                      Technology,
                    </a>
                    <a href="#">Politics,</a>
                    <a href="#">Lifestyle</a>
                  </div>
                  <ul className="blog_meta list">
                    <li>
                      <a href="#">
                        Mark wiens
                        <i className="fa fa-user-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        12 Dec, 2017
                        <i className="fa fa-calendar-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        1.2M Views
                        <i className="fa fa-eye" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        06 Comments
                        <i className="fa fa-comment-o" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="blog_post">
                  <img src="images/blog/main-blog/m-blog-2.jpg" alt="" />
                  <div className="blog_details">
                    <a href="blog-details.html">
                      <h4>The Basics Of Buying A Telescope</h4>
                    </a>
                    <p style={{color: "#070707"}}>
                      MCSE boot camps have its supporters and its detractors.
                      Some people do not understand why you should have to spend
                      money on boot camp when you can get the MCSE study
                      materials yourself at a fraction.
                    </p>
                    <a href="blog-details.html" className="template-btn">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </article>
            <article className="row blog_item">
              <div className="col-md-3">
                <div className="blog_info text-right">
                  <div className="post_tag">
                    <a href="#">Food,</a>
                    <a className="active" href="#">
                      Technology,
                    </a>
                    <a href="#">Politics,</a>
                    <a href="#">Lifestyle</a>
                  </div>
                  <ul className="blog_meta list">
                    <li>
                      <a href="#">
                        Mark wiens
                        <i className="fa fa-user-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        12 Dec, 2017
                        <i className="fa fa-calendar-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        1.2M Views
                        <i className="fa fa-eye" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        06 Comments
                        <i className="fa fa-comment-o" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="blog_post">
                  <img src="images/blog/main-blog/m-blog-3.jpg" alt="" />
                  <div className="blog_details">
                    <a href="blog-details.html">
                      <h4>The Glossary Of Telescopes</h4>
                    </a>
                    <p style={{color: "#070707"}}>
                      MCSE boot camps have its supporters and its detractors.
                      Some people do not understand why you should have to spend
                      money on boot camp when you can get the MCSE study
                      materials yourself at a fraction.
                    </p>
                    <a href="blog-details.html" className="template-btn">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </article>
            <article className="row blog_item">
              <div className="col-md-3">
                <div className="blog_info text-right">
                  <div className="post_tag">
                    <a href="#">Food,</a>
                    <a className="active" href="#">
                      Technology,
                    </a>
                    <a href="#">Politics,</a>
                    <a href="#">Lifestyle</a>
                  </div>
                  <ul className="blog_meta list">
                    <li>
                      <a href="#">
                        Mark wiens
                        <i className="fa fa-user-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        12 Dec, 2017
                        <i className="fa fa-calendar-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        1.2M Views
                        <i className="fa fa-eye" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        06 Comments
                        <i className="fa fa-comment-o" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="blog_post">
                  <img src="images/blog/main-blog/m-blog-4.jpg" alt="" />
                  <div className="blog_details">
                    <a href="blog-details.html">
                      <h4>The Night Sky</h4>
                    </a>
                    <p style={{color: "#070707"}}>
                      MCSE boot camps have its supporters and its detractors.
                      Some people do not understand why you should have to spend
                      money on boot camp when you can get the MCSE study
                      materials yourself at a fraction.
                    </p>
                    <a href="blog-details.html" className="template-btn">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </article>
            <article className="row blog_item">
              <div className="col-md-3">
                <div className="blog_info text-right">
                  <div className="post_tag">
                    <a href="#">Food,</a>
                    <a className="active" href="#">
                      Technology,
                    </a>
                    <a href="#">Politics,</a>
                    <a href="#">Lifestyle</a>
                  </div>
                  <ul className="blog_meta list">
                    <li>
                      <a href="#">
                        Mark wiens
                        <i className="fa fa-user-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        12 Dec, 2017
                        <i className="fa fa-calendar-o" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        1.2M Views
                        <i className="fa fa-eye" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        06 Comments
                        <i className="fa fa-comment-o" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="blog_post">
                  <img src="images/blog/main-blog/m-blog-5.jpg" alt="" />
                  <div className="blog_details">
                    <a href="blog-details.html">
                      <h4>Telescopes 101</h4>
                    </a>
                    <p style={{color: "#070707"}}>
                      MCSE boot camps have its supporters and its detractors.
                      Some people do not understand why you should have to spend
                      money on boot camp when you can get the MCSE study
                      materials yourself at a fraction.
                    </p>
                    <a href="blog-details.html" className="template-btn">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </article>
            <nav className="blog-pagination justify-content-center d-flex">
              <ul className="pagination">
                <li className="page-item">
                  <a href="#" className="page-link" aria-label="Previous">
                    <span aria-hidden="true">
                      <span className="fa fa-angle-left" />
                    </span>
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    01
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    04
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    09
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link" aria-label="Next">
                    <span aria-hidden="true">
                      <span className="fa fa-angle-right" />
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="blog_right_sidebar">
            
            {/* <aside className="single_sidebar_widget author_widget">
              <img
                className="author_img rounded-circle"
                src="images/blog/author.png"
                alt=""
              />
              <h5>Charlie Barber</h5>
              <p>Senior blog writer</p>
              <div className="social_icon">
                <a href="#">
                  <i className="fa fa-facebook" />
                </a>
                <a href="#">
                  <i className="fa fa-twitter" />
                </a>
                <a href="#">
                  <i className="fa fa-github" />
                </a>
                <a href="#">
                  <i className="fa fa-behance" />
                </a>
              </div>
              <p style={{color: "#070707"}}>
                Boot camps have its supporters andit sdetractors. Some people do
                not understand why you should have to spend money on boot camp
                when you can get. Boot camps have itssuppor ters andits
                detractors.
              </p>
              <div className="br" />
            </aside> */}
            <aside className="single_sidebar_widget popular_post_widget">
              <h4 className="widget_title">Popular Posts</h4>
              <div className="media post_item">
                <img
                  src="images/blog/popular-post/post1.jpg"
                  alt="post"
                />
                <div className="media-body">
                  <a href="blog-details.html">
                    <h5>Space The Final Frontier</h5>
                  </a>
                  <p>02 Hours ago</p>
                </div>
              </div>
              <div className="media post_item">
                <img
                  src="images/blog/popular-post/post2.jpg"
                  alt="post"
                />
                <div className="media-body">
                  <a href="blog-details.html">
                    <h5>The Amazing Hubble</h5>
                  </a>
                  <p>02 Hours ago</p>
                </div>
              </div>
              <div className="media post_item">
                <img
                  src="images/blog/popular-post/post3.jpg"
                  alt="post"
                />
                <div className="media-body">
                  <a href="blog-details.html">
                    <h5>Astronomy Or Astrology</h5>
                  </a>
                  <p>03 Hours ago</p>
                </div>
              </div>
              <div className="media post_item">
                <img
                  src="images/blog/popular-post/post4.jpg"
                  alt="post"
                />
                <div className="media-body">
                  <a href="blog-details.html">
                    <h5>Asteroids telescope</h5>
                  </a>
                  <p>01 Hours ago</p>
                </div>
              </div>
              <div className="br" />
            </aside>
            {/* <aside className="single_sidebar_widget ads_widget">
              <a href="#">
                <img
                  className="img-fluid"
                  src="images/blog/add.jpg"
                  alt=""
                />
              </a>
              <div className="br" />
            </aside> */}
            <aside className="single_sidebar_widget post_category_widget">
              <h4 className="widget_title">Post Catgories</h4>
              <ul className="list cat-list">
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Technology</p>
                    <p>37</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Lifestyle</p>
                    <p>24</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Fashion</p>
                    <p>59</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Art</p>
                    <p>29</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Food</p>
                    <p>15</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Architecture</p>
                    <p>09</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex justify-content-between">
                    <p>Adventure</p>
                    <p>44</p>
                  </a>
                </li>
              </ul>
              <div className="br" />
            </aside>
            {/* <aside className="single-sidebar-widget newsletter_widget">
              <h4 className="widget_title">Newsletter</h4>
              <p>
                Here, I focus on a range of items and features that we use in
                life without giving them a second thought.
              </p>
              <div className="form-group d-flex flex-row">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fa fa-envelope" aria-hidden="true" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="Enter email"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter email'"
                  />
                </div>
                <a href="#" className="bbtns">
                  Subscribe
                </a>
              </div>
              <p className="text-bottom">You can unsubscribe at any time</p>
              <div className="br" />
            </aside>
            <aside className="single-sidebar-widget tag_cloud_widget">
              <h4 className="widget_title">Tag Clouds</h4>
              <ul className="list">
                <li>
                  <a href="#">Technology</a>
                </li>
                <li>
                  <a href="#">Fashion</a>
                </li>
                <li>
                  <a href="#">Architecture</a>
                </li>
                <li>
                  <a href="#">Fashion</a>
                </li>
                <li>
                  <a href="#">Food</a>
                </li>
                <li>
                  <a href="#">Technology</a>
                </li>
                <li>
                  <a href="#">Lifestyle</a>
                </li>
                <li>
                  <a href="#">Art</a>
                </li>
                <li>
                  <a href="#">Adventure</a>
                </li>
                <li>
                  <a href="#">Food</a>
                </li>
                <li>
                  <a href="#">Lifestyle</a>
                </li>
                <li>
                  <a href="#">Adventure</a>
                </li>
              </ul>
            </aside> */}
          </div>
        </div>
      </div>
    </div>
  </section>
        {/*================Blog Area =================*/}
        {/* start footer Area */}
        <Footer />
        {/* End footer Area */}
        {/*gmaps Js*/}
      </div>
      ;
    </Fragment>
  );
};
export default Blog;
